import React from 'react';

const GoogleMapsIframe = () => {
    return (
        <iframe
            title="Barbershop Salong CR"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2132.399461710412!2d11.9547434!3d57.6926829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3d042682aeb16514!2sCR%20Salong!5e0!3m2!1ssv!2sse!4v1656368511328!5m2!1ssv!2sse"
            width="100%"
            height="500"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0">
        </iframe>
    );
};

export default GoogleMapsIframe;
