import React from 'react';

const Contacts = () => {
    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-title">
                        <h6>Adress</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p style={ { marginTop: "1.2rem" }}>Nordenskiöldsgatan 5, 413 09 Göteborg</p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title">
                        <h6>Telefon</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p style={ { marginTop: "1.2rem" }}>
                            <a href="tel:0737684771">0737684771</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
