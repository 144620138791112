import React from 'react';
import {useParams} from "react-router-dom"
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import SubscribeForm from "../components/form/SubscribeForm";
import Footer from "../blocks/footer/Footer";

const Confirmation = () => {
    const {id} = useParams();
    return (
        <div>
            <Loading />
            <Header logoColor="dark" />
            <div className="card container border border-dark labels" style={{ marginTop: "100px", marginBottom: "50px", minHeight: "500px", lineHeight: "normal" }}>
                <div className="card-body">
                    <h1 className="card-title">Din boking är genomförd</h1>
                    <h4 >Du har bokat en tid hos CR Salong</h4>
                    <div className="row">
                        <div className="col-3 labels">Plats</div>
                        <div className="col-9 labels">Nordenskiöldsgatan 5</div>
                        <div className="col-3">Tid</div>
                        <div className="col 9 labels">
                            <div>{id}</div>
                        </div>
                    </div>

                    <SubscribeForm data={id} />
                </div>

            </div>
            <Footer/>
        </div>
    );
}

export default Confirmation;
