import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import Calendar from "../components/Bookings/Calendar";
import PageTitleBooking from "../blocks/page-title/PageTitleBooking";
import {Link} from 'react-router-dom';
import {Alert, Button} from 'react-bootstrap';

const Bookings = () => {
    document.body.classList.add( 'page' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Bokningar | CR Salong</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Bokning barbershop CR Salong, Boka tid hos CR Salong" />
                <meta name="keywords" content="cr salong barbershop salong cr bästa frisör i göteborg, gbg frisör, linneplatsen frisör, frisör linneplatsen salong linneplatsen, hårklippning linneplatsen, barbershop linneplatsen, barbershop göteborg, barbershop gbg, hårklippning linneplatsen, hårklippning CR Salong linneplatsen, hårklippning göteborg, hårklippning gbg, salong cr barbershop, göteborg barbershop, salong cr, linneplatsen damklipp, linneplatsen herrklipp, boka frisör linneplatsen, linneplatsen boka frisör" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleBooking />
                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                <Calendar style={{ paddingBottom: "300px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Bookings;
