import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInside'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';

const ServicesInside = () => {
    document.body.classList.add( 'single' );
    document.body.classList.add( 'single-adveits_services' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Priser | CR salong</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Priser CR salong" />
                <meta name="keywords" content="cr salong barbershop salong cr bästa frisör i göteborg, gbg frisör, linneplatsen frisör, frisör linneplatsen salong linneplatsen, hårklippning linneplatsen, barbershop linneplatsen, barbershop göteborg, barbershop gbg, hårklippning linneplatsen, hårklippning CR Salong linneplatsen, hårklippning göteborg, hårklippning gbg, salong cr barbershop, göteborg barbershop, salong cr, linneplatsen damklipp, linneplatsen herrklipp, boka frisör linneplatsen, linneplatsen boka frisör" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleServicesInside />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="price-list" className="block spacer">
                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    <div className="price-list-item-title">
                                                        <h3>Hår och skägg</h3>
                                                    </div>

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Herrklippning</span>
                                                            <span className="list-group-price">320 kr</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Pensionärer</span>
                                                            <span className="list-group-price">280 kr</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Barn</span>
                                                            <span className="list-group-price">280 kr</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Hår och skägg</span>
                                                            <span className="list-group-price">450 kr</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">Skägg</span>
                                                            <span className="list-group-price">250 kr</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside;
