import React from 'react';

const AboutOurTeam = () => {
    return (
        <div id="our-team" className="block spacer p-top-xl">
            <div className="title">
                <h2 className="hr">Frisör</h2>
            </div>

            <div className="row gutter-width-sm with-pb-md">
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/CVHFbhv/1fb61994-5241-4c41-a549-79e0ef1450c2.jpg" alt="Weam"/>
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position">
                                <p>Barberare</p>
                            </div>

                            <h4 className="team-member-t-head">Wesam</h4>

                            <div className="team-member-description">
                                <p>En erfaren och träffsäker barberare med över 15 års erfarenhet inom yrket. Ett gott öga för stil som skräddarsyr efter vad som passar i samråd med kunden.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutOurTeam;
