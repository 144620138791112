import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import PageTitlePolicy from '../blocks/page-title/PageTitlePolicy';

const Policy = () => {
    document.body.classList.add('page');

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Integritetspolicy | CR salong</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="CR salong integritetspolicy" />
                <meta name="keywords" content="cr salong barbershop salong cr bästa frisör i göteborg, gbg frisör, linneplatsen frisör, frisör linneplatsen salong linneplatsen, hårklippning linneplatsen, barbershop linneplatsen, barbershop göteborg, barbershop gbg, hårklippning linneplatsen, hårklippning CR Salong linneplatsen, hårklippning göteborg, hårklippning gbg, salong cr barbershop, göteborg barbershop, salong cr, linneplatsen damklipp, linneplatsen herrklipp, boka frisör linneplatsen, linneplatsen boka frisör" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitlePolicy />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                <h3>1. Vilka personuppgifter samlar vi in om dig och varför?</h3>
                                <p>Vi samlar in följande personuppgifter på vår salong. Namn, E-postadress, Telefonnummer</p>
                                <p>Vi samlar in personuppgifter med ett huvudändamål (Se nedan). Dessa uppgifter samlas in för att:</p>

                                <div>
                                    <p><span style={{ fontWeight: "bold" }}>Personuppgifter för utförande av tjänst:</span> Vi ska kunna boka och utföra våra tjänster samt kontakta dig för att boka om, boka av eller erbjuda en återbudstid då förhinder uppstår eller ledig tid finns att erbjuda.</p>
                                </div>

                                <div className="block spacer p-top-lg">
                                    <h3>2. Hur samlar vi in personuppgifter?</h3>
                                    <p>Personuppgifter samlas in genom att du lämnar personuppgifter vid bokning via hemsidan, kontakt med oss via e-post, telefon eller fysiskt besök på vår salong.</p>
                                </div>

                                <div className="block spacer p-top-lg">
                                    <h3>3. Vilken rättslig grund har vi för hanteringen av personuppgifter?</h3>
                                    <p>Följande rättsliga grunder finns för hanteringen av personuppgifter: Med stöd i fullföljande av avtal för personuppgifter för utförande av tjänst.</p>
                                </div>

                                <div className="block spacer p-top-lg">
                                    <h3>4. Säkerhet</h3>
                                    <p>Vi använder IT-system för att skydda sekretessen, integriteten och tillgången till dina personuppgifter. Vi har vidtagit särskilda säkerhetsåtgärder för att skydda dina personuppgifter mot olovlig eller obehörig behandling (såsom olovlig tillgång, förlust, förstörelse eller skada). Endast de personer som faktiskt behöver behandla dina personuppgifter för att vi ska kunna uppfylla våra angivna ändamål har tillgång till dem.</p>
                                </div>

                                <div className="block spacer p-top-lg">
                                    <h3>5. Rätt till radering</h3>
                                    <p>Vi sparar Personuppgifter (i de fall Kund inte begärt om en radering eller ändring av personuppgifter under avtalstid) så länge som Kund är kund hos oss.</p>
                                    <p>Som fysisk person har du alltid rätt att vända dig till oss och be att dina personuppgifter raderas. I följande fall måste personuppgifter raderas:</p>
                                    <ul>
                                        <li>Om personuppgifterna inte längre behövs för de ändamål som vi har samlat in och behandlar dem för;</li>
                                        <li>Om behandlingen grundar sig enbart på ditt samtycke och du återkallar detta samtycke;</li>
                                        <li>Om behandlingen sker för direktmarknadsföring och du motsätter dig att dina personuppgifter behandlas;</li>
                                        <li>Om du motsätter dig den personuppgiftsbehandlingen som sker efter en intresseavvägning och det inte finns berättigande skäl som väger tyngre än ditt intresse;</li>
                                        <li>Om personuppgifter inte behandlas enligt regleringen;</li>
                                        <li>Om radering krävs för att uppfylla en rättslig skyldighet.</li>
                                    </ul>
                                </div>

                                <div className="block spacer p-top-lg">
                                    <h3>6. Klagomål</h3>
                                    <p>Om du som fysisk person anser att vi behandlar dina personuppgifter i strid med gällande dataskyddsreglering bör du anmäla detta till oss så fort som möjligt.</p>
                                </div>

                                <div className="block spacer p-top-lg">
                                    <h3>7. Kontaktuppgifter</h3>
                                    <h5>Kontakta oss för att utöva dina rättigheter</h5>
                                    <p>Om du som fysisk person vill utöva dina rättigheter så som din rätt till rättelse, radering av personuppgifter, begära en begränsning av behandling återkallande av samtycke eller lämna klagomål <a href={process.env.PUBLIC_URL + "/contacts"}>kontakta oss.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Policy;
