import React from 'react';

const AboutsUs = () => {
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h3 className="text-uppercase">Är du ute efter en herr- eller damklippning eller rakning med kvalité? Då har du kommit rätt. Vi arbetar på ett sätt som gör att du kan överlåta vården av ditt hår och skägg till oss.</h3>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p>Det sägs att det är bättre att se en gång än att höra tusen gånger. Vi är professionella med över 15 år lång erfarenhet inom våra tjänster. Vi är stolta över att majoriteten av kunder som har besökt oss en gång vanligtvis beslutar att återvända</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutsUs;
