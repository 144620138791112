import React from 'react';
import HeaderLogo from '../../blocks/logo/HeaderLogo';
import MenuModal from '../../components/modal/MenuModal';
import Actions from "../actions/Actions";

const Header = ( { logoColor } ) => {
    return (
        <header id="header" className="site-header">
            <div className="wrapper">
                <div className="header-content d-flex justify-content-between">
                    <div className="header-left align-self-center">
                        <div className="links">
                            <div className="links-items">
                                <div className="links-item">
                                    <a href={ process.env.PUBLIC_URL + "/" } className="btn btn-lg btn-link border-0 p-0 min-w-auto">HEM</a>
                                </div>
                            </div>
                        </div>
                        <a className="header-left booking-header" href={ process.env.PUBLIC_URL + "/bookings" }>
                            <span>Boka tid</span>
                        </a>
                    </div>

                    <div className="header-center align-self-center">
                        <HeaderLogo logoColor={ logoColor } />
                    </div>

                    <div className="header-right d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                            <a style={{ marginRight: "1.2rem" }} className="btn btn-lg btn-link border-0 p-0 min-w-auto text-uppercase" href="tel:0737684771"><p>RING OSS<span><i style={{ fontSize: "1rem", marginLeft: "5px" }} className="fas fa-phone"></i></span></p></a>
                            <MenuModal />
                        </div>

                        <Actions />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
