import React , {useState}from "react";
import { useQuery } from "react-query";
import moment from "moment";
import customEvent from "./CustomEvent";
import customHeaderCell from "./CustomHeaderCell";
import {fetchBookingData} from './utils/bookingData'
import WeekCalendar from "react-week-calendar";
import './utils/style.css'
import CustomModal from "./CustomModal";

export default function Calendar() {
   const { data: selectedInterval, error, isLoading } = useQuery("sightings", fetchBookingData);
    const [weekIncrement, SetWeekIncrement] = useState(1);
    const [dayIncrement, SetDayIncrement] = useState(0);
    const [isMobile, SetSsMobile] = useState(window.innerWidth < 800);
    return (
        <div className="" id="" style={{marginTop:""}}>
            <div className="row  ">
                <div className=" col-4  font-weight-bold "  align={"center"}>
                    { !isMobile ?
                        <p  aria-disabled={(weekIncrement > -1)} onClick={()=>
                    {   if(weekIncrement > 0)
                        SetWeekIncrement( weekIncrement - 7) }}> <i className="fas fa-arrow-circle-left"/> tidigare </p>:
                        <p  aria-disabled={(dayIncrement > -1)} onClick={()=>
                        {   if(dayIncrement > 0)
                            SetDayIncrement( dayIncrement - 1) }}> <i className="fas fa-arrow-circle-left"/> tidigare </p>

                    }
                </div>
                <div className="col-4 font-weight-bold " align={"center"}>
                    { isMobile? <p> { moment().add( dayIncrement, "d").format("DD/MM") } </p> : <p >   { moment({h: 9, m: 0}).weekday(weekIncrement).format("DD/MM")}
                        - {moment({h: 9, m: 0}).weekday(weekIncrement + 6).format("DD/MM")}</p>}

                </div>
                <div className="col-4 font-weight-bold" align={"center"}>
              <p  onClick={()=> !isMobile ? SetWeekIncrement( weekIncrement + 7): SetDayIncrement(dayIncrement + 1)} >
                  Senare <i className="fas fa-arrow-circle-right"></i> </p>
                </div>
            </div>
            <br  style={{height:"100px"}}/>
        <WeekCalendar
            firstDay={isMobile ? moment({h: 9, m: 0}).add(dayIncrement,"d") : moment({h: 9, m: 0}).weekday(weekIncrement) }
            startTime={moment({h: 10, m: 0})}
            endTime={moment({h: 19, m: 0})}
          selectedIntervals={isLoading? [] : selectedInterval}
            scaleUnit={30}
            cellHeight={40}
            numberOfDays={isMobile? 1 : 7}
            showModalCase={['edit']}
          eventComponent={customEvent}
            headerCellComponent={customHeaderCell}
            modalComponent={CustomModal}
        />
        </div>
    );
}
