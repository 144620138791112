import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAuth } from '../contexts/AuthContext';
import { database } from '../firebase';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Header from '../blocks/header/Header';
import HourSelect from '../components/Bookings/HourSelect';
import Accordion from '../blocks/ui/components/collapse/Accordion';
// import { mockData } from './dataex';

export default function Dashboard() {
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [toggleAll, setToggleAll] = useState(false);
  const [toggleComming, setToggleComming] = useState(false);
  const [toggleToday, setToggleToday] = useState(false);
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  const [comingBookings, setcomingBookings] = useState([]);
  const [todaysBookings, settodaysBookings] = useState([]);
  const [inputDate, setInputDate] = useState('');
  const [inputDateCombinedClosedHours, setInputDateCombinedClosedHours] =
    useState('');
  const [dateMsg, setDateMsg] = useState('');
  const [closedHoursMsg, setClosedHoursMsg] = useState('');
  const [closedDatesAndHours, setClosedDatesHours] = useState('');
  const queryClient = useQueryClient();
  const [closedDays, setClosedDays] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState({
    start: '',
    end: '',
  });

  const handleIntervalChange = (type, selectedHour) => {
    setSelectedInterval((prevInterval) => ({
      ...prevInterval,
      [type]: selectedHour,
    }));
  };

  async function fetchBookingData() {
    const data = [];
    let closedDates = [];
    let closedDatesAndHoursList = [];
    await database.ref('/boka').once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        var childData = childSnapshot.val();
        let det = { key: childSnapshot.key, child: childData, type: 'boka' };
        data.push(det);
      });
    });

    await database.ref('/boka1').once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        var childData = childSnapshot.val();
        let det = { key: childSnapshot.key, child: childData, type: 'boka1' };
        data.push(det);
      });
    });

    await database.ref('/dates').once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childData = childSnapshot.val().date;
        closedDates.push(childData);
      });
    });

    await database.ref('/hoursclose').once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childData = childSnapshot.val();
        closedDatesAndHoursList.push(childData);
      });
    });

    const sortedDataByDay = data.sort(
      (a, b) => a.child.NoOfDay - b.child.NoOfDay
    );

    const sortedDataByTimes = sortedDataByDay.sort((a, b) => {
      const dateA = moment(a.child.reservedAt, 'DD/MM/YYYY- HH:mm');
      const dateB = moment(b.child.reservedAt, 'DD/MM/YYYY- HH:mm');

      if (dateA.isBefore(dateB)) return -1;
      if (dateA.isAfter(dateB)) return 1;
      return 0;
    });

    setcomingBookings(
      sortedDataByTimes.filter((i) => {
        const itemDate = moment(i.child.reservedAt, 'DD/MM/YYYY');

        // Checking if the item's date is in the future
        return itemDate.isAfter(moment(), 'day');
      })
    );
    settodaysBookings(
      sortedDataByTimes.filter((i) => {
        let itemDate = moment(i.child.reservedAt, 'DD/MM/YYYY');
        return moment().isSame(itemDate, 'day');
      })
    );
    setClosedDays(closedDates);
    setClosedDatesHours(closedDatesAndHoursList);
    return sortedDataByTimes;
  }

  const { data, errors, isLoading } = useQuery('sightings', fetchBookingData);
  const mutation = useMutation(
    (idToDelete) => {
      database.ref('boka/' + idToDelete).remove();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sightings');
      },
    }
  );

  const mutation1 = useMutation(
    (idToDelete) => {
      database.ref('boka1/' + idToDelete).remove();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sightings');
      },
    }
  );

  const deleteDateMutation = useMutation(
    async (dateToDelete) => {
      const snapshot = await database
        .ref('dates')
        .orderByChild('date')
        .equalTo(dateToDelete)
        .once('value');

      // Get the key from the snapshot
      const key = Object.keys(snapshot.val())[0];

      // Delete the entry using the key
      return database.ref('dates/' + key).remove();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sightings');
        console.log('Date successfully deleted');
      },
      onError: (error) => {
        console.error('Error deleting date:', error.message);
      },
    }
  );

  const handleDeleteClick = (dateIdToDelete) => {
    deleteDateMutation.mutate(dateIdToDelete);
  };

  async function handleLogout() {
    setError('');

    try {
      await logout();
      history.push('/login');
    } catch {
      setError('Failed to log out');
    }
  }

  const handleClick = (key, type) => {
    if (window.confirm('Är du säker på att du vill ta bort bokningen?')) {
      type === 'boka' ? mutation.mutate(key) : mutation1.mutate(key);
    }
  };

  const removeAllBookings4ever = () => {
    if (window.confirm('Är du säker på att du vill ta bort ALLA BOKNINGAR?')) {
      database.ref('boka1').remove();
      database.ref('boka').remove();
    }
  };

  const removeAllDateTimeClose = () => {
    if (window.confirm('Är du säker på att du vill ta bort ALLA DATUM?')) {
      database.ref('hoursclose').remove();
    }
  };

  const renderAllBooking = () => {
    return (
      <div className="mt-5 ">
        {toggleAll && (
          <div className="row p-4 border border-dark">
            <div className="col-3">Bokad tid</div>
            <div className="col-3">Namn</div>
            <div className="col-3">Nummer</div>
            <div className="col-3">Tabort</div>
          </div>
        )}
        {!isLoading &&
          toggleAll &&
          data.reverse().map((i) => (
            <div key={i.key}>
              <div className="row p-4 ">
                <div className="col-3"> {i.child.reservedAt}</div>
                <div className="col-3"> {i.child.username}</div>
                <div className="col-3">
                  {' '}
                  {i.child.phone?.replace(/[\s-]+/g, '')}
                </div>
                <div className="col-3">
                  <button
                    style={{ marginTop: '-0.5rem' }}
                    onClick={() => handleClick(i.key, i.type)}
                    type="button"
                    className="btn btn-danger btn-sm"
                  >
                    Tabort
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };
  const renderTodays = () => {
    return (
      <div className="mt-5 ">
        {toggleToday && (
          <div className="row p-4 border border-dark">
            <div className="col-3">Bokad tid</div>
            <div className="col-3">Namn</div>
            <div className="col-3">Nummer</div>
            <div className="col-3">Tabort</div>
          </div>
        )}
        {!isLoading &&
          toggleToday &&
          todaysBookings.map((i) => (
            <div key={i.key}>
              <div className="row p-4 ">
                <div className="col-3"> {i.child.reservedAt}</div>
                <div className="col-3"> {i.child.username}</div>
                <div className="col-3">
                  {' '}
                  {i.child.phone?.replace(/[\s-]+/g, '')}
                </div>
                <div className="col-3">
                  <button
                    style={{ marginTop: '-0.5rem' }}
                    onClick={() => handleClick(i.key, i.type)}
                    type="button"
                    className="btn btn-danger btn-sm"
                  >
                    Tabort
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };
  const renderCommingBooking = () => {
    const today = moment();
    const year = today.year();

    return (
      <div className="mt-5  ">
        {toggleComming && (
          <div className="row p-4 border border-dark">
            <div className="col-3 ">Bokad tid</div>
            <div className="col-3">Namn</div>
            <div className="col-3">Nummer</div>
            <div className="col-3">Tabort</div>
          </div>
        )}
        {!isLoading &&
          toggleComming &&
          comingBookings
            .filter((i) => i?.child?.reservedAt?.includes(String(year)))
            .map((i) => (
              <div key={i.key}>
                <div className="row p-4">
                  <div className="col-3"> {i.child.reservedAt}</div>
                  <div className="col-3"> {i.child.username}</div>
                  <div className="col-3">
                    {' '}
                    {i.child.phone?.replace(/[\s-]+/g, '')}
                  </div>
                  <div className="col-3">
                    <button
                      style={{ marginTop: '-0.5rem' }}
                      onClick={() => handleClick(i.key, i.type)}
                      type="button"
                      className="btn btn-danger btn-sm"
                    >
                      Tabort
                    </button>
                  </div>
                </div>
              </div>
            ))}
      </div>
    );
  };

  const toggleChecked = () => {
    let val;
    setChecked((value) => {
      val = !value;
      window.localStorage.setItem('anstalld', String(val));
      database.ref('anstalld/' + 1).set({
        uid: 1,
        value: val,
      });
      return !value;
    });
  };

  const toggleCheckedClosedStore = () => {
    let val;
    setChecked((value) => {
      val = !value;
      window.localStorage.setItem('closed', String(val));
      database.ref('closed/' + 1).set({
        uid: 1,
        value: val,
      });
      return !value;
    });
  };

  const addNewDateToFirebase = () => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    if (datePattern.test(inputDate)) {
      const newDateRef = database.ref('dates').push();
      newDateRef
        .set({
          date: inputDate,
        })
        .then(() => {
          setDateMsg(
            `Datum har lagts till, inga bokningar kommer finnas ${inputDate}`
          );
        })
        .catch((error) => {
          setDateMsg('Tekniskt fel, gör en felanmälan');
        });
    } else {
      setDateMsg(
        'Skriv datum day-month-year DD-MM-YY eller year-month-day YY-MM-DD'
      );
    }
  };

  const addHoursCloseToFirebase = () => {
    setClosedHoursMsg('');
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!selectedInterval.start || !selectedInterval.end) {
      setClosedHoursMsg('Du måste välja både start och slut tid');
      return;
    }

    if (!inputDateCombinedClosedHours) {
      setClosedHoursMsg('Du måste välja ett datum, exempel 2024-05-01');
      return;
    }
    if (selectedInterval.start === selectedInterval.end) {
      setClosedHoursMsg('Start och slut tid får inte vara samma');
      return;
    }

    if (!datePattern.test(inputDateCombinedClosedHours)) {
      setClosedHoursMsg('Fel datum, datum måste skrivas 2024-XX-XX');
      return;
    }

    if (datePattern.test(inputDateCombinedClosedHours)) {
      const newDateRef = database.ref('hoursclose').push();
      newDateRef
        .set({
          ...selectedInterval,
          date: inputDateCombinedClosedHours,
        })
        .then(() => {
          setClosedHoursMsg(
            `Tider har lagts till, stängt ${inputDateCombinedClosedHours}, KL ${selectedInterval.start}-${selectedInterval.end}`
          );
        })
        .catch((error) => {
          setClosedHoursMsg('Tekniskt fel, gör en felanmälan');
        });
    }
  };

  return (
    <>
      <Header logoColor="dark" />
      <div className="container">
        <h2 className="flex justify-content-center text-center mb-4">Admin</h2>
        <div className="row">
          <div className="col-12">
            {error && <Alert variant="danger">{error}</Alert>}
            <strong>Email:</strong> {currentUser.email}
            <br />
            <button
              style={{ marginTop: '1rem' }}
              className="btn btn-dark btn-sm text-uppercase"
              variant="link"
              onClick={handleLogout}
            >
              Logga ut
            </button>
            <label
              style={{ marginTop: '1rem', marginLeft: '1rem' }}
              className="btn btn-dark btn-sm text-uppercase"
              variant="link"
            >
              <input
                type="checkbox"
                checked={window.localStorage.getItem('anstalld') === 'true'}
                onChange={toggleChecked}
              />
              ANVÄND TVÅ ANSTÄLLDA
            </label>
          </div>
          <button
            style={{ marginTop: '1rem', marginLeft: '1rem' }}
            className="btn btn-danger btn-sm text-uppercase"
            variant="link"
            onClick={removeAllBookings4ever}
          >
            TA BORT ALLA BOKNINGAR (VARNING)
          </button>
          <label
            style={{ marginTop: '1rem', marginLeft: '1rem' }}
            className="btn btn-dark btn-sm text-uppercase"
            variant="link"
          >
            <input
              type="checkbox"
              checked={window.localStorage.getItem('closed') === 'true'}
              onChange={toggleCheckedClosedStore}
            />
            STÄNG ALLA TIDER
          </label>
          <br />
        </div>
        <br />
        <Accordion>
          <div title="Stängda dagar">
            <div style={{ padding: '0.5rem' }}>
              <label>
                Skriv en dag som är stängt
                <input
                  type="text"
                  placeholder="Exempel 2024-05-01"
                  value={inputDate}
                  onChange={(e) => setInputDate(e.target.value)}
                />
              </label>
              <button
                className="btn btn-dark btn-sm text-uppercase"
                onClick={addNewDateToFirebase}
              >
                Lägg till
              </button>
              <div
                style={{
                  color: dateMsg.includes('Tekniskt fel') ? 'red' : 'green',
                }}
              >
                {dateMsg}
              </div>
              {closedDays.length > 0 ? (
                <div style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                  <p style={{ fontSize: '14px' }}>
                    Kommande stängda dagar nedan
                  </p>
                </div>
              ) : null}
              {closedDays.length > 0
                ? closedDays.map((day, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.5rem',
                      }}
                    >
                      <p style={{ marginRight: '0.5rem' }}>{day}</p>
                      <p
                        style={{ marginLeft: '0.5rem' }}
                        className="btn btn-danger btn-sm text-uppercase"
                        variant="link"
                        onClick={() => handleDeleteClick(String(day))}
                      >
                        Ångra
                      </p>
                    </div>
                  ))
                : null}
            </div>
          </div>
          <div title="Välj att stänga en tid">
            <div
              style={{
                padding: '0.5rem',
                marginTop: '1rem',
              }}
            >
              <label style={{ marginTop: '10px' }}>
                Skriv en dag och välj tid som är stängt
                <input
                  type="text"
                  placeholder="Exempel 2024-05-05"
                  value={inputDateCombinedClosedHours}
                  onChange={(e) =>
                    setInputDateCombinedClosedHours(e.target.value)
                  }
                />
              </label>
              <HourSelect
                selectedInterval={selectedInterval}
                onIntervalChange={(type, selectedHour) =>
                  handleIntervalChange(type, selectedHour)
                }
              />
              <button
                className="btn btn-dark btn-sm text-uppercase"
                style={{ marginTop: '1rem' }}
                onClick={addHoursCloseToFirebase}
              >
                Spara
              </button>
              {closedDatesAndHours?.length ? (
                <>
                  <div style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                    <p style={{ fontSize: '14px' }}>
                      Kommande stängda tider nedan
                    </p>
                  </div>
                  <button
                    className="btn btn-danger btn-sm text-uppercase"
                    variant="link"
                    onClick={() => removeAllDateTimeClose()}
                  >
                    Ta bort alla datum
                  </button>
                </>
              ) : null}
              {closedDatesAndHours?.length > 0
                ? closedDatesAndHours.map((dates, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '0.5rem',
                          marginTop: '0.5rem',
                        }}
                      >
                        <p style={{ marginRight: '0.5rem', color: 'green' }}>
                          {dates.date} {dates.start} - {dates.end}
                        </p>
                      </div>
                    );
                  })
                : null}
              <div
                style={{
                  color: closedHoursMsg.includes('Tekniskt fel')
                    ? 'red'
                    : 'green',
                  marginTop: '1rem',
                }}
              >
                {closedHoursMsg}
              </div>
            </div>
          </div>
        </Accordion>
      </div>
      <div className="row" style={{ marginTop: '50px', marginBottom: '20px' }}>
        <div className="col-4 ">
          <button
            className="btn btn-primary btn-sm"
            style={{ minHeight: '3rem' }}
            onClick={() => {
              setToggleToday(!toggleToday);
              setToggleComming(false);
              setToggleAll(false);
            }}
          >
            Se dagens bokningar
          </button>
        </div>
        <div className="col-4">
          <button
            className="btn btn-primary btn-sm"
            style={{ minHeight: '3rem' }}
            onClick={() => {
              setToggleComming(!toggleComming);
              setToggleAll(false);
              setToggleToday(false);
            }}
          >
            Se kommande bokningar
          </button>
        </div>
        <div className="col-4">
          <button
            style={{ minHeight: '3rem' }}
            className="btn btn-primary btn-sm"
            onClick={() => {
              setToggleAll(!toggleAll);
              setToggleComming(false);
              setToggleToday(false);
            }}
          >
            Se alla bokningar
          </button>
        </div>
      </div>

      {toggleComming && renderCommingBooking()}
      {toggleAll && renderAllBooking()}
      {toggleToday && renderTodays()}
    </>
  );
}
