import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleServices from '../blocks/page-title/PageTitleServices';
import ServicesContent from "../blocks/services/ServicesContent";

const Services = () => {
    document.body.classList.add( 'archive' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Tjänster | CR Salong</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Tjanster CR salong, " />
                <meta name="keywords" content="cr salong barbershop salong cr bästa frisör i göteborg, gbg frisör, linneplatsen frisör, frisör linneplatsen salong linneplatsen, hårklippning linneplatsen, barbershop linneplatsen, barbershop göteborg, barbershop gbg, hårklippning linneplatsen, hårklippning CR Salong linneplatsen, hårklippning göteborg, hårklippning gbg, salong cr barbershop, göteborg barbershop, salong cr, linneplatsen damklipp, linneplatsen herrklipp, boka frisör linneplatsen, linneplatsen boka frisör" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleServices />

                <div id="page-content" className="spacer p-top-xl">
                    <ServicesContent />
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Services;
