import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleTerms from '../blocks/page-title/PageTitleTerms';

const Terms = () => {
    document.body.classList.add( 'page' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Villkor | Salong CR</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Villkor CR salong" />
                <meta name="keywords" content="cr salong barbershop salong cr bästa frisör i göteborg, gbg frisör, linneplatsen frisör, frisör linneplatsen salong linneplatsen, hårklippning linneplatsen, barbershop linneplatsen, barbershop göteborg, barbershop gbg, hårklippning linneplatsen, hårklippning CR Salong linneplatsen, hårklippning göteborg, hårklippning gbg, salong cr barbershop, göteborg barbershop, salong cr, linneplatsen damklipp, linneplatsen herrklipp, boka frisör linneplatsen, linneplatsen boka frisör" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleTerms />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                               <h3>1. Avbokning</h3>
                                <p>Avbokning ska ske senast 24 timmar innan behandling via telefon eller besök. Om du har sjukdomssymtom gäller inte 24-timmarsregeln och avbokning kan göras närsomhelst innan din bokade behandling startar. Avbokningen måste dock bekräftas för att den ska räknas som giltig. Du kan avboka din tid via telefon. Vid senare avbokning än 24 timmar innan din bokade behandling utan giltig anledning räknas det som utebliven närvaro. Vid upprepade avbokningar förbehåller vi oss rätten att neka kunder att boka tid.</p>
                                <h3>2. Sjukdom/förhinder frisör</h3>
                                <p>Om någon av våra frisörer blir sjuka eller får akut förhinder meddelas detta till dig så snart som möjligt. Du erbjuds då första lediga tid som passar dig.</p>
                                <h3>3. Lagring av personuppgifter</h3>
                                <p>Vi hanterar dina personuppgifter varsamt. När du i samband med kontakt med oss lämnar personuppgifter om dig själv behandlar vi dem enligt vår <a href={ process.env.PUBLIC_URL + "/policy" }> Integritetspolicy.</a> I de fall samtycke krävs får du skriftligen godkänna hur vi hanterar dina personuppgifter.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Terms;
