import React, { Component } from 'react';
import axios from 'axios';

class SubscribeForm extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            values: {
                email: '',
                time: this.props.data
            },
            successMessage: "Bekräftelsen har skickats till din mejl, var god och kolla även skräppost",
            warningMessage: 'Fyll i formuläret',
            errorMessage: 'Tekniskt fel, tyvärr kunde inte mejlet skickas, var god och spara ner den bokade tiden',
            alertClass: '',
            responseMessage: '',
            alertTimeout: '',
            delay: 5000,
            isDone: false
        };
    }

    submitForm = async e => {
        e.preventDefault();
        if ( document.querySelector( '#alert' ) ) {
            document.querySelector( '#alert' ).remove();
        }

        this.setState( { isSubmitting: true } );


        axios.post( 'https://email-service-navy.vercel.app/api/sendgrid', { "firstName":"salongcr@gmail.com", "":"CRsalong", "email":this.state.values.email, "message":this.state.values.time }).then( response => {
            if ( response.data.status === 'success' ) {
                console.log(response.data.status);
                this.setState( { isDone: !this.state.isDone } );
                this.setState( { responseMessage: this.state.successMessage } );
            }

            if ( response.data.status === 'warning' ) {
                this.setState( { responseMessage: this.state.warningMessage } );
            }

            if ( response.data.status === 500 || response.data.status === 'error' ) {
                this.setState( { responseMessage: this.state.errorMessage } );
            }

            this.callAlert( this.state.responseMessage, response.data.status )
        } ).catch( error => {
            this.callAlert( this.state.errorMessage, 'error' )
        } );
    };


    removeAlert = () => {
        clearTimeout( this.state.alertTimeout );

        this.setState( {
            alertTimeout: setTimeout( function () {
                    var element = document.querySelector( '#alert' );
                    element.classList.remove( 'fadeIn' );
                    element.classList.add( 'fadeOut' );

                    setTimeout( function () {
                        element.remove()
                    }, 1200 )
                }, this.state.delay
            )
        } );
    };

    callAlert = ( message, type ) => {
        if ( !document.querySelector( '#alert' ) ) {
            if ( type === 'success' ) {
                this.setState( { alertClass: 'success' } )
            }

            if ( type === 'error' ) {
                this.setState( { alertClass: 'danger' } )
            }

            if ( type === 'warning' ) {
                this.setState( { alertClass: 'warning' } )
            }

            var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' + this.state.alertClass + '">' + message + '</div>';

            var element = document.querySelector( '.newsletter-form' );

            element.insertAdjacentHTML( 'beforeend', alert );

            this.removeAlert();
        }
    };

    handleInputChange = e =>
        this.setState( {
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        } );

    render() {
        return (
            <form onSubmit={ this.submitForm } className="newsletter-form top-no-space" style={{marginTop: "50px"}}>
                <div className="input-group" style={{marginTop: "50px"}}>
                    <p style={{marginBottom: "10px"}} className="bold">Skicka bokningsbekräftelse till din mejl</p>
                    <input name="email"
                           value={ this.state.values.email }
                           onChange={ this.handleInputChange }
                           id="cf-2-email"
                           type="email"
                           placeholder="mail@example.com"
                           required="required" />

                    <button style={{ marginTop: "1rem" }} className="input-group-append btn btn-dark btn-sm text-uppercase link-no-space" disabled={ this.state.isDone }>
                        Skicka bekräftelse
                    </button>
                </div>
            </form>
        );
    }
}

export default SubscribeForm;
