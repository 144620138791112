import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleGallery from '../blocks/page-title/PageTitleGallery';
import GalleryContent from '../blocks/gallery/GalleryContent';

const Gallery = () => {
    document.body.classList.add( 'page' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Galleri | Salong CR</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="barbershop bilder salong CR barbershop salong CR bästa frisör i göteborg, gbg frisör, linneplatsen frisör, frisör linneplatsen salong linneplatsen, hårklippning linneplatsen, barbershop linneplatsen, barbershop göteborg, barbershop gbg, hårklippning linneplatsen, hårklippning salong CR, hårklippning göteborg, hårklippning gbg, salong CR, linneplatsen barbershop, göteborg barbershop, salong CR, linneplatsen damklipp, linneplatsen herklipp, boka frisör linneplatsen, linneplatsen boka frisör" />
                <meta name="keywords" content="salong CR barbershop salong CR bästa frisör i göteborg, gbg frisör, linneplatsen frisör, frisör linneplatsen salong linneplatsen, hårklippning linneplatsen, barbershop linneplatsen, barbershop göteborg, barbershop gbg, hårklippning linneplatsen, hårklippning salong CR, hårklippning göteborg, hårklippning gbg, salong CR, linneplatsen barbershop, göteborg barbershop, salong CR, linneplatsen damklipp, linneplatsen herklipp, boka frisör linneplatsen, linneplatsen boka frisör" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleGallery />

                <section id="page-content" className="spacer p-top-xl">
                    <GalleryContent render ={false} />
                </section>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Gallery;
