import React from 'react';
import moment from 'moment'
const Copyright = () => {
    return (
        <div className="copyright">
            <p>© {moment().year()}  Salong CR / <a className="secondary-semibold" href={ process.env.PUBLIC_URL + "/contacts" }>Kontakt</a></p>
        </div>
    );
};

export default Copyright;
